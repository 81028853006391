<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('busAuth2[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="tool">
        <table style="text-align: center;width: 95%">
          <tr><td> {{ $t("wallet.default[19]") }}:{{busmoney}} USDT </td></tr>
          <tr><td> {{$t('wallet.default[17]')}}:{{busdeposit}} USDT</td></tr>
        </table>
      </div>
      <van-form class="mt15" @submit="onSubmit">

        <div class="panel">
          <div style="text-align: left">{{$t('wallet.default[18]')}}</div>
          <van-uploader v-model="busauthInfo.screenshots" max-count="1"
                        :after-read="afterRead"/>
        </div>
        <div style="text-align: center;margin-top: 20px">
          <p class="btn" @click="submitAuth">{{$t('busAuth2[2]')}}</p>
        </div>
      </van-form>
    </div>
<!--      <van-dialog v-model="showNotice"  :title="$t('wallet.default[16]')"-->
<!--                  :show-confirm-button="true">-->
<!--        <div style="width: 90%;margin-top: 10px;margin-bottom: 30px;margin-left: 20px">-->
<!--          <dd v-html="InitData.helpList[1].content"></dd>-->
<!--        </div>-->
<!--      </van-dialog>-->
<!--    <van-dialog v-model="showRecharge"  :title="$t('wallet.default[21]')" @confirm="gotoRecharge"-->
<!--                :show-confirm-button="true"  :show-cancel-button="true">-->
<!--      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">-->
<!--        {{busmoney}} USDT,{{$t('wallet.default[23]')}}-->
<!--      </div>-->
<!--    </van-dialog>-->
    </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      showNotice: false,
      showRecharge:false,
      busauthInfo:{},
      infoData: '',
      busmoney:0,
      busdeposit:0,
    }
  },
  computed: {

  },
  watch: {
    "InitData.helpList"(data) {
      if (!this.$parent.isNotice && data.length>1) this.showNotice = true;
    },
  },
  created() {
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log(this.InitData.helpList)
    this.busmoney = parseInt(this.UserInfo.bus_amount);
	let balance = this.UserInfo.balance;
	this.busdeposit = parseInt(this.UserInfo.bus_deposit);
	if (balance<(this.busmoney+this.busdeposit)){
	  this.showRecharge = true;
    }
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    },
    gotoRecharge(){
      this.$router.push("/user/recharge");
    },
    submitAuth(){
      this.$Model.GobusAuth(this.busauthInfo,data=>{
        var msg = '';
        if (data.code==1){
          msg = this.$t('common4[1]');
        }else if (data.code==0){
          msg = data.code_dec
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.replace("/");
        }

      });
    },
    afterRead(file) {
      debugger
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
          } else {
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    }
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #FFFAEF;
  }

  .van-nav-bar {
    background-color: #FFFAEF;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#000;
    font-weight: bold;
  }

  .PageBox>>>.tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    border-radius: 10px;
    background-color: rgba(244,211,79,1);
    color: #000;
    height: 120px;
    box-shadow: 0 1px 3px #dbdbdbdb;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .panel {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
  }
.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}

/* .van-nav-bar>>>.van-icon{
	color:#fff;
} */
.van-nav-bar>>>.van-nav-bar__title{
	color:#000;
  font-weight: bold;
}
.ScrollBox{
	padding:0 35px;
}
.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}
  .btn {
    width: 100%;
    padding: 10px 50px;
    border-radius: 5px;
    background-color: #F4D34F;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin: 15px 0px 30px;
  }
</style>
